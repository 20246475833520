/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@font-face {
  font-family: 'Rakesh';
  src: url('./assets/fonts/Coiny-Cyrillic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: 18px;
}

body {
  margin: 0;
  font-family: 'Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: 'Rakesh';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
